import codamImage from "../images/projects/codam.png"
import idealImage from "../images/projects/ideal.png"
import agileProjectManagementProjectImage from "../images/projects/agile-project-management.png"
import takeawayPointsImage from "../images/projects/justeat-takeaway.png"
import infoEmergingIndustriesImage from "../images/projects/emerging-industries.png"
import hanUniversityImage from "../images/projects/han.png"
import lloydsEchoImage from "../images/projects/lloyds-echo-money-talks-experiment.png"

import {
  codamCodingCollegeDescription1,
  codamCodingCollegeDescription2,
  codamCodingCollegeDescription3,
  codamCodingCollegeDescription4,
  idealPaymentsDescription1,
  idealPaymentsDescription2,
  agileProductManagementDescription,
  infoEmergingIndustriesDescription1,
  infoEmergingIndustriesDescription2,
  takeawayPointsDescription1,
  takeawayPointsDescription2,
  hanUniversityDescription,
  lloydsEchoDescription,
} from "./projects-text"

const codamProject = {
  video: false,
  image: {
    value: codamImage,
    alt: "Codam Coding University",
  },
  title: "Software Student Mentorship",
  description:
    "Mentoring and serving the next generation of software engineers @ Codam Coding College.",
  longDescription1: codamCodingCollegeDescription1,
  longDescription2: codamCodingCollegeDescription2,
  longDescription3: codamCodingCollegeDescription3,
  longDescription4: codamCodingCollegeDescription4,
  link: {
    href: "https://www.codam.nl/",
    target: "_blank",
    rel: "noopener noreferrer",
    value: "Learn more about Codam University.",
    pageIdentifier: "/projects-codam",
  },
}

const idealProject = {
  video: false,
  image: {
    value: idealImage,
    alt: "Ideal Payments",
  },
  title: "Ideal Payments Integration",
  description: "Integrating the iDEAL payments network for online banking.",
  longDescription1: idealPaymentsDescription1,
  longDescription2: idealPaymentsDescription2,
  link: {
    href: "https://www.ideal.nl/en/",
    target: "_blank",
    rel: "noopener noreferrer",
    value: "Learn more about the iDEAL protocol.",
    pageIdentifier: "/projects-ideal",
  },
}

const agileProjectManagementProject = {
  video: true,
  image: {
    value: agileProjectManagementProjectImage,
    alt: "Agile Project Management",
  },
  title: "Agile Project Management",
  description:
    "Explaining the concept of Agile Project Management & Software Delivery.",
  longDescription: agileProductManagementDescription,
  link: {
    href: "https://info.nl/",
    target: "_blank",
    rel: "noopener noreferrer",
    value: "Learn more about Info Agency.",
    pageIdentifier: "/projects-agile",
  },
}

const takeawayProject = {
  video: false,
  image: {
    value: takeawayPointsImage,
    alt: "Takeaway.com & JustEat.com",
  },
  title: "Take Away Punten",
  description: "Providing Just Eat & Takewaway customers with loyalty points.",
  longDescription1: takeawayPointsDescription1,
  longDescription2: takeawayPointsDescription2,
  link: {
    href: "https://www.thuisbezorgd.nl/en/punten",
    target: "_blank",
    rel: "noopener noreferrer",
    value: "Learn more about TakeAway.com & Takeaway Punten.",
    pageIdentifier: "/projects-takeaway",
  },
}

const infoEmergingIndustriesProject = {
  video: true,
  image: {
    value: infoEmergingIndustriesImage,
    alt: "Emerging Industries",
  },
  title: "Emerging Industries",
  description:
    "Exploring the definition of technology within Emerging Industries.",
  longDescription1: infoEmergingIndustriesDescription1,
  longDescription2: infoEmergingIndustriesDescription2,
  link: {
    href: "https://info.nl/",
    target: "_blank",
    rel: "noopener noreferrer",
    value: "Learn more about Info Agency.",
    pageIdentifier: "/projects-emerging-industries",
  },
}

const hanUniversityProject = {
  video: false,
  image: {
    value: hanUniversityImage,
    alt: "HAN Open Science Platform",
  },
  title: "HAN Open Science Platform",
  description:
    "Providing a research platform tool for students and industry experts.",
  longDescription: hanUniversityDescription,
  link: {
    href: "https://han-front-production.azurewebsites.net/",
    target: "_blank",
    rel: "noopener noreferrer",
    value: "Learn more about the HAN platform.",
    pageIdentifier: "/projects-han",
  },
}

const lloydsEchoProject = {
  video: true,
  image: {
    value: lloydsEchoImage,
    alt: "Lloyds Bank & Amazon Echo",
  },
  title: "Lloyds Bank & Amazon Echo",
  description:
    "Voice Controlled Online Banking @ Lloyds Banking Group Using The Amazon Echo.",
  longDescription: lloydsEchoDescription,
  link: {
    href: "https://www.lloydsbank.com/",
    target: "_blank",
    rel: "noopener noreferrer",
    value: "Learn more about Lloyds Bank.",
    pageIdentifier: "/projects-lloyds",
  },
}

export {
  codamProject,
  idealProject,
  infoEmergingIndustriesProject,
  agileProjectManagementProject,
  takeawayProject,
  hanUniversityProject,
  lloydsEchoProject,
}
