const infoEmergingIndustriesDescription1 =
  'An "Emerging Industry" amongst other things may refer to companies that are formed around a new product or idea that is in the early stages of development or adoption. Whilst working at INFO Business Innovation Partner Agency, we had a lot of clients and projects in so called "Emerging Industries".'

const infoEmergingIndustriesDescription2 =
  "Operating in such an industry means you have to deal with a lot of uncertainty, having to figure out a lot from scratch and having to validate your product or service thoroughly and quickly. In this short video we discussed our thoughts on our part to play in this field."

const agileProductManagementDescription =
  "Agile Product Development is the application of principles of Agile Software Development to various management processes, particularly project management. Different companies implement their own flavour of agile, and shape their propositions to their specific recipie. In this video, I explain how we applied this at INFO Business Innovation Partner Agency."

const takeawayPointsDescription1 =
  "Whilst working for Info Business Innovation Partner Agency, one of my projects involved a collaboration with Just Eat Takeaway.com where we created an integrated a scalable loyalty platform. Customers could earn points by ordering food and redeeming those points for vouchers and offers from Takeaway.com and their partners."

const takeawayPointsDescription2 =
  "The platform is now live in six countries, and more than 1.5 billion loyalty points from 13 million users have been migrated to the new platform. The next time you order food from Takeaway.com, be sure to redeem your points on the platform and grab some great deals and offers in exchange!"

const lloydsEchoDescription =
  'The "Money Talks" experiment was carried out as an R & D project designed to allow customers who are Amazon Echo device users to make enquiries on their bank account information using the voice command functionality of the Alexa platform.'

const hanUniversityDescription =
  "Research at the HAN University yields valuable knowledge products, such as publications and theses. The HAN believes that it is important that these products are easy to find and accessible. In this way, the knowledge gained can be widely used and can be built on quickly. The plaform provided facilitates this effort."

const codamCodingCollegeDescription1 =
  "Codam prepares the new generation for the jobs of today and tomorrow. They offer high-quality software engineering training that is accessible to the largest possible and diverse group of people."

const codamCodingCollegeDescription2 =
  "I joined the Spring 2021 Codam Mentorship Program as a mentor in order to share my knowledge and experience with the software engineering students to better help equip them for a fruitful future within the field of software engineering from both a technical and business perspective."

const codamCodingCollegeDescription3 =
  "I mentored two students in total for the duration of the program. One student was focussed on web application design - namely JavaScript frameworks such as React.js and Node.js, whilst the other student was focussed on Python and Artificial Intelligence."

const codamCodingCollegeDescription4 =
  " It was a great initiative by Codam Coding College, and I very much look forward to seeing the further progress and journey's of those two talented students in the future."

const idealPaymentsDescription1 =
  "iDEAL is an online payment method that enables consumers to pay online through their own bank. In addition to webshops, other organisations that are not part of the e-commerce market also offer iDEAL."

const idealPaymentsDescription2 =
  "iDEAL is increasingly used to pay energy bills, make donations to charities, buy mobile credits, pay local taxes, traffic fines, etc. I implemented this for NN Bank to help support iDEAL payments as an Issuer Bank."

export {
  infoEmergingIndustriesDescription1,
  infoEmergingIndustriesDescription2,
  agileProductManagementDescription,
  takeawayPointsDescription1,
  takeawayPointsDescription2,
  lloydsEchoDescription,
  hanUniversityDescription,
  codamCodingCollegeDescription1,
  codamCodingCollegeDescription2,
  codamCodingCollegeDescription3,
  codamCodingCollegeDescription4,
  idealPaymentsDescription1,
  idealPaymentsDescription2,
}
