import React, { Fragment } from "react"

import "../styles/projectCodam.scss"

import Contact from "../components/New-Contact-Form/new-contact-form"
import TestimonialBelt from "../components/Testimonial-Belt/testimonial-belt"

import { codamProject } from "../constants/new-ghost-projects"

import codamCollegeTerrain from "../images/projects/codamx-audience.jpg"

const ProjectCodam = () => {
  return (
    <Fragment>
      <div className="project-codam-title">
        {/* <h1>Software Student Mentorship</h1> */}
      </div>
      <div className="project-codam-description">
        <p className="first-codam-paragraph">{codamProject.longDescription1}</p>
        <p>{codamProject.longDescription2}</p>
        <p>{codamProject.longDescription3}</p>
        <p>{codamProject.longDescription4}</p>
      </div>
      <div className="project-codam-main-container">
        <img src={codamCollegeTerrain} alt="Codam College Terrain" />
      </div>
      <TestimonialBelt />
      <Contact />
    </Fragment>
  )
}

export default ProjectCodam
